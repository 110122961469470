import { PagePanel } from '@/components/page/PagePanel';
import { useState, useValue } from '@/lib/composables';
import { createComponent } from '@/lib/vue';
import { ProntuarioPacientePanelContent } from './prontuarioPacientePanel/ProntuarioPacientePanelContent';
import { ProntuarioPacientePanelHeader } from './prontuarioPacientePanel/ProntuarioPacientePanelHeader';

export const ProntuarioPacientePanel = createComponent({
  name: 'ProntuarioPacientePanel',
  setup(props, ctx) {
    const $paciente = useState(s => s.prontuario.paciente);
    const [$open, setOpen] = useValue(false);

    const closePanel = () => setOpen(false);

    return () => {
      if (!$paciente.value) {
        return null;
      }

      return (
        <PagePanel bgColorClass="bg-white" v-model={$open.value}>
          <ProntuarioPacientePanelHeader slot="header" />

          <ProntuarioPacientePanelContent closePanel={closePanel} />
        </PagePanel>
      );
    };
  },
});
