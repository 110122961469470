import {
  PacienteEmail,
  PacienteFones,
} from '@/components/paciente/PacienteComponents';
import { useState } from '@/lib/composables';
import { formatCep, formatEndereco } from '@/lib/form';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IPacienteFragment } from '@/typings';

interface IProps {
  closePanel: () => void;
}

export const ProntuarioPacientePanelContent = createComponent<IProps>({
  name: 'ProntuarioPacientePanelContent',
  props: {
    closePanel: { type: Function, required: true },
  },
  setup(props, ctx) {
    const $paciente = useState(s => s.prontuario.paciente?.data);

    return () =>
      $paciente.value && (
        <div class="flex flex-col">
          <div class="flex flex-col text-gray-700">
            {dadosAdicionaisGroup($paciente.value)}

            {enderecoGroup($paciente.value)}

            {informacoesAdicionaisGroup($paciente.value)}
          </div>

          {actionsRow({
            props,
            paciente: $paciente.value,
          })}
        </div>
      );
  },
});

const dadosAdicionaisGroup = ({
  celular,
  telefoneCasa,
  email,
  sexo,
  profissao,
  estadoCivil,
}: IPacienteFragment) =>
  (celular || telefoneCasa || email || sexo || profissao || estadoCivil) && (
    <div class="flex flex-col text-body-sm">
      <div class="uppercase text-body">Dados adicionais</div>

      <v-divider class="mb-2" />

      <div class="flex flex-col space-y-1">
        <PacienteFones celular={celular} telefoneCasa={telefoneCasa} />

        <PacienteEmail email={email} />

        <div class="flex space-x-12">
          {sexo && (
            <div>
              Sexo:
              <span class="ml-1 font-medium capitalize">
                {sexo.toLowerCase()}
              </span>
            </div>
          )}

          {profissao && (
            <div>
              Profissão:
              <span class="ml-1 font-medium">{profissao}</span>
            </div>
          )}

          {estadoCivil && (
            <div>
              Estado civil:
              <span class="ml-1 font-medium">{estadoCivil}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );

const enderecoGroup = ({
  enderecoCep,
  enderecoLogradouro,
  enderecoNumero,
  enderecoComplemento,
  enderecoBairro,
  enderecoCidade,
  enderecoUf,
}: IPacienteFragment) =>
  (enderecoLogradouro || (enderecoCidade && enderecoUf)) && (
    <div class="flex flex-col mt-4 text-body-sm">
      <div class="uppercase text-body">Endereço</div>

      <v-divider class="mb-2" />

      {enderecoLogradouro && (
        <div>
          {formatEndereco({
            logradouro: enderecoLogradouro,
            numero: enderecoNumero,
            complemento: enderecoComplemento,
          })}
        </div>
      )}

      {enderecoCidade && (
        <div>
          {formatEndereco({
            bairro: enderecoBairro,
            cidade: enderecoCidade,
            uf: enderecoUf,
          })}
        </div>
      )}

      {enderecoCep && <div>CEP: {formatCep(enderecoCep)}</div>}
    </div>
  );

const informacoesAdicionaisGroup = ({
  informacoesAdicionais,
}: IPacienteFragment) =>
  informacoesAdicionais && (
    <div class="flex flex-col mt-4 text-body-sm">
      <div class="uppercase text-body">Informações adicionais</div>

      <v-divider class="mb-2" />

      <div class="whitespace-pre-wrap">{informacoesAdicionais}</div>
    </div>
  );

const actionsRow = ({
  props,
  paciente: { id },
}: {
  props: IProps;
  paciente: IPacienteFragment;
}) => (
  <div class="flex my-2">
    <v-btn text color="secondary" onClick={props.closePanel}>
      Ocultar detalhes
    </v-btn>

    <v-spacer />

    <v-btn outlined color="primary" to={Routes.app.pacientes.edit.index(id)}>
      Ver cadastro
    </v-btn>
  </div>
);
