import { createComponent } from '@/lib/vue';
import { IMenu } from '@/typings';
import { computed } from '@vue/composition-api';
import { ContextSidebarMenuHeader } from './ContextSidebarMenuHeader';
import { ContextSidebarMenuSubheader } from './ContextSidebarMenuSubheader';
import { ContextSidebarMenuTileAction } from './ContextSidebarMenuTileAction';
import { ContextSidebarMenuTileLink } from './ContextSidebarMenuTileLink';

interface IProps {
  menus: readonly IMenu[];
}

export const ContextSidebarMenu = createComponent<IProps>({
  name: 'ContextSidebarMenu',
  props: {
    menus: { type: Array, required: true },
  },
  setup(props, ctx) {
    const $menus = computed(() => props.menus.filter(f => !f.hidden));

    return () => <v-list>{$menus.value.map(menuItem)}</v-list>;
  },
});

const menuItem = (menu: IMenu, index: number) => {
  if (menu.header && menu.text) {
    return <ContextSidebarMenuHeader key={index} title={menu.text} />;
  } else if (menu.subheader && menu.text) {
    return <ContextSidebarMenuSubheader key={index} title={menu.text} />;
  } else if (menu.divider) {
    return <v-divider key={index} />;
  } else if (menu.action) {
    return <ContextSidebarMenuTileAction key={index} menu={menu} />;
  } else if (menu.to) {
    return <ContextSidebarMenuTileLink key={index} menu={menu} />;
  }
};
