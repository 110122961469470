import { createComponent } from '@/lib/vue';

interface IProps {
  title: string;
}

export const ContextSidebarMenuSubheader = createComponent<IProps>({
  name: 'ContextSidebarMenuSubheader',
  props: {
    title: { type: String, required: true },
  },
  setup(props, ctx) {
    return () => <v-subheader>{props.title}</v-subheader>;
  },
});
