import { ContextSidebar } from '@/components/sidebars/context/ContextSidebar';
import { ContextSidebarMenu } from '@/components/sidebars/context/ContextSidebarMenu';
import { ContextSidebarMenuHeader } from '@/components/sidebars/context/ContextSidebarMenuHeader';
import { useState } from '@/lib/composables';
import { createComponent } from '@/lib/vue';
import { ProntuarioState } from '@/store/modules/prontuario';
import { prontuarioPages } from '@/store/modules/prontuario/utils';
import { IMenu, IProntuarioPageState } from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';
import { ProntuarioCustomPage } from '../../pages/tabs/ProntuarioCustomPage';
import { ProntuarioAtendimentoBtn } from './prontuarioSidebar/ProntuarioAtendimentoBtn';
import { ProntuarioAtendimentoTimer } from './prontuarioSidebar/ProntuarioAtendimentoTimer';

export default createComponent({
  name: 'ProntuarioSidebar',
  setup(props, ctx) {
    const { $menus } = useMenus(ctx);

    return () => (
      <ContextSidebar>
        <v-list>
          <ContextSidebarMenuHeader title="Prontuários" />
        </v-list>

        <div class="flex flex-col my-2">
          <ProntuarioAtendimentoBtn />

          <ProntuarioAtendimentoTimer />
        </div>

        <v-divider />

        <ContextSidebarMenu menus={$menus.value} />
      </ContextSidebar>
    );
  },
});

function useMenus(ctx: SetupContext) {
  const $pageId = useState(s => s.prontuario.page.id);

  const $submitted = useState(s => s.prontuario.atendimento.submitted);

  const $hidden = useState(s => !s.prontuario.atendimento.playing);

  const $tabs = useState(s => s.prontuario.tabs);

  const mapMenu = ({
    page,
    show = false,
    $hasError,
  }: {
    page: IProntuarioPageState;
    show?: boolean;
    $hasError?: Ref<boolean>;
  }): IMenu => ({
    text: page.title,
    action: () => ProntuarioState.setPage(page),
    active: page.id === $pageId.value,
    hidden: !show && $hidden.value,
    hasError: $submitted.value && !!$hasError?.value,
  });

  const $menus = computed<IMenu[]>(() => [
    // Histórico clínico
    mapMenu({ page: prontuarioPages.historicoClinico, show: true }),
    // Pré-atendimento
    mapMenu({
      page: prontuarioPages.preAtendimento,
      $hasError: useState(s => s.prontuario.forms.preAtendimento.hasError),
    }),
    // Custom Menus
    ...$tabs.value.map(v =>
      mapMenu({
        page: {
          id: v.id,
          title: v.nome,
          component: ProntuarioCustomPage,
        },
      }),
    ),
    // Solicitação de exames
    mapMenu({
      page: prontuarioPages.exames,
      $hasError: useState(s => s.prontuario.forms.exames.hasError),
    }),
    // Prescrições
    mapMenu({
      page: prontuarioPages.prescricoes,
      $hasError: useState(s => s.prontuario.forms.prescricoes.hasError),
    }),
    // Hipótese diagnóstica
    mapMenu({
      page: prontuarioPages.hipoteseDiagnostica,
      $hasError: useState(s => s.prontuario.forms.hipoteseDiagnostica.hasError),
    }),
    // Atestados
    mapMenu({
      page: prontuarioPages.atestados,
      $hasError: useState(s => s.prontuario.forms.atestados.hasError),
    }),
    // Imagens e anexos
    mapMenu({ page: prontuarioPages.files }),
  ]);

  return { $menus };
}
