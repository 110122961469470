import { CircularLoading } from '@/components/loading/CircularLoading';
import { MyPage } from '@/components/page/MyPage';
import { useState } from '@/lib/composables';
import { useRoute } from '@/lib/composables/utils/useRouter';
import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import { ProntuarioPacientePanel } from '../components/paciente/ProntuarioPacientePanel';

export default createComponent({
  name: 'ProntuarioPage',
  setup(props, ctx) {
    ProntuarioService.loadAll({
      agendamentoId: useRoute().query.aid as string | null,
      pacienteId: useRoute().params.id,
    });

    const $loading = useState(s => s.prontuario.loading);

    const $page = useState(s => s.prontuario.page);

    return () => {
      const PageComponent = $page.value.component;

      return (
        <div class="flex flex-col">
          <MyPage title={$page.value.title} class="mb-6">
            <CircularLoading loading={$loading.value}>
              <ProntuarioPacientePanel />
            </CircularLoading>
          </MyPage>

          <v-fade-transition mode="out-in">
            {!$loading.value && <PageComponent key={$page.value.id} />}
          </v-fade-transition>
        </div>
      );
    };
  },
});
