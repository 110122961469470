import { createComponent } from '@/lib/vue';
import { IMenu } from '@/typings';

interface IProps {
  menu: IMenu;
}

export const ContextSidebarMenuTileAction = createComponent<IProps>({
  name: 'ContextSidebarMenuTileAction',
  props: {
    menu: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => {
      const { menu } = props;

      const errorText = { 'text-error': !!menu.hasError };

      return (
        <v-list-item
          onClick={menu.action}
          ripple
          inputValue={menu.active}
          class={{ 'text-primary': menu.active }}
        >
          {menu.icon && (
            <v-list-item-action>
              <v-icon class={errorText}>{menu.icon}</v-icon>
            </v-list-item-action>
          )}

          <v-list-item-content class={errorText}>
            <v-list-item-title>{menu.text}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      );
    };
  },
});
