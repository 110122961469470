import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { useState } from '@/lib/composables';
import {
  IFormEvents,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { createComponent } from '@/lib/vue';
import { ProntuarioState } from '@/store/modules/prontuario';
import { IProntuarioPageState } from '@/typings';
import { Ref, SetupContext } from '@vue/composition-api';
import { ProntuarioCardForm } from './shared/ProntuarioCardForm';

interface IProps {}

interface IEvents extends IFormEvents<any> {}

export const ProntuarioCustomForm = createComponent<IProps, IEvents>({
  name: 'ProntuarioCustomForm',
  setup(props, ctx) {
    const { $page, $tab, $form, $schema, emitSubmit } = useComputeds(ctx);

    const { handleValueInput } = useEvents($page);

    return () =>
      $tab.value && (
        <ProntuarioCardForm>
          <MyForm form={$form.value} noCancel noSummary onSubmit={emitSubmit}>
            <FormFields
              form={$form.value}
              schema={$schema.value}
              class="m-4"
              value={$tab.value.model}
              onInput={handleValueInput}
            />
          </MyForm>
        </ProntuarioCardForm>
      );
  },
});

function useComputeds(ctx: SetupContext) {
  const $page = useState(s => s.prontuario.page);

  const $tab = useState(s =>
    s.prontuario.tabs.find(f => f.id === $page.value.id),
  );

  const { $form, $schema, emitSubmit } = useFormConfig({
    ctx,
    initialValue: $tab.value?.model || {},
    mapSchema: () => $tab.value?.schema || {},
  });

  return { $page, $tab, $form, $schema, emitSubmit };
}

function useEvents($page: Ref<IProntuarioPageState>) {
  function handleValueInput(model) {
    ProntuarioState.tabs.setModel({ tabId: $page.value.id, model });
  }

  return { handleValueInput };
}
