import { createComponent } from '@/lib/vue';
import { IMenu } from '@/typings';
import { MyTheme } from '@/lib/helpers/MyTheme';

interface IProps {
  menu: IMenu;
}

export const ContextSidebarMenuTileLink = createComponent<IProps>({
  name: 'ContextSidebarMenuTileLink',
  props: {
    menu: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => {
      const { menu } = props;

      const errorClass = menu.hasError ? 'text-error' : '';

      return (
        <v-list-item exact={!menu.noExact} ripple to={menu.to}>
          {listItemAction({ menu, errorClass })}

          <v-list-item-content class={errorClass}>
            <v-list-item-title>{menu.text}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      );
    };
  },
});

function listItemAction({
  menu,
  errorClass,
}: {
  menu: IMenu;
  errorClass: string;
}) {
  if (menu.imageUrl) {
    return (
      <v-list-item-action>
        <v-avatar color={MyTheme.cyan800} size={24}>
          <img src={menu.imageUrl} alt={menu.text} />
        </v-avatar>
      </v-list-item-action>
    );
  } else if (menu.icon) {
    return (
      <v-list-item-action>
        <v-icon class={errorClass}>{menu.icon}</v-icon>
      </v-list-item-action>
    );
  }

  return null;
}
