import { createComponent } from '@/lib/vue';
import { computed } from '@vue/composition-api';
import { FormHeader } from '../typography/FormHeader';

interface IProps {
  value?: boolean;
  title?: string;
  bgColorClass?: string;
}

export const PagePanel = createComponent<IProps>({
  name: 'PagePanel',
  props: {
    value: { type: Boolean, default: true },
    title: { type: String },
    bgColorClass: { type: String, default: 'bg-coolGray-100' },
  },
  setup(props, ctx) {
    const $panel = computed(() => (props.value ? 0 : null));

    const emitInput = val => ctx.emit('input', val === 0);

    return () => {
      const defaultSlot = ctx.slots.default?.();

      const headerSlot = ctx.slots.header?.();

      return (
        <v-expansion-panels
          id="PagePanel"
          value={$panel.value}
          class="flat"
          onChange={emitInput}
        >
          <v-expansion-panel>
            <v-expansion-panel-header class={props.bgColorClass}>
              {headerSlot || <FormHeader noPadding title={props.title} />}
            </v-expansion-panel-header>

            <v-expansion-panel-content ripple class={props.bgColorClass}>
              <div class="flex flex-col px-4">{defaultSlot}</div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      );
    };
  },
});

export default PagePanel;
