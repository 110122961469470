import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import { ProntuarioCustomForm } from '../../components/forms/ProntuarioCustomForm';

export const ProntuarioCustomPage = createComponent({
  name: 'ProntuarioCustomPage',
  setup(props, ctx) {
    return () => (
      <div>
        <ProntuarioCustomForm onSubmit={() => ProntuarioService.cache.save()} />
      </div>
    );
  },
});
