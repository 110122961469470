import {
  PacienteConvenio,
  PacienteInfo,
} from '@/components/paciente/PacienteComponents';
import { Avatar } from '@/components/utils/Avatar';
import { useState } from '@/lib/composables';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { computed } from '@vue/composition-api';

export const ProntuarioPacientePanelHeader = createComponent({
  name: 'ProntuarioPacientePanelHeader',
  setup(props, ctx) {
    const {
      $paciente,
      $faltas,
      $atendimentos,
      $convenio,
      $dataNascimento,
      $ultimoAtendimento,
    } = useComputeds();

    return () => {
      if (!$paciente.value) {
        return null;
      }

      const { nome, imagem } = $paciente.value;

      return (
        <div class="flex items-center">
          <Avatar imgSrc={imagem?.thumbnailUrl} nome={nome} class="mr-4" />

          <div class="flex flex-col w-8/12 mb-2 text-gray-700 text-body-sm">
            <div class="text-title text-coolGray-700">{nome}</div>

            <div class="flex flex-col space-y-1">
              <PacienteInfo
                value={$dataNascimento.value}
                icon={MyIcons.aniversariantes}
              />

              <PacienteConvenio convenio={$convenio.value} />

              <div class="flex">
                <div class="mr-10">
                  Faltas:
                  <span class="ml-1 font-medium">{$faltas.value}</span>
                </div>

                <div>
                  Atendimentos:
                  <span class="ml-1 font-medium">{$atendimentos.value}</span>
                </div>
              </div>

              {$ultimoAtendimento.value && (
                <div class="flex">
                  Último atendimento:
                  <span class="ml-1 font-medium">
                    {$ultimoAtendimento.value}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    };
  },
});

function useComputeds() {
  const $pacienteState = useState(s => s.prontuario.paciente);

  const $agendamento = useState(s => s.prontuario.agendamento);

  const $paciente = computed(() => $pacienteState.value?.data);

  const $atendimentos = computed(
    () => $pacienteState.value?.stats.atendimentos || 0,
  );

  const $faltas = computed(() => $pacienteState.value?.stats.faltas || 0);

  const $ultimoAtendimento = computed(() =>
    DateHelpers.humanize($pacienteState.value?.stats.dataUltimoAtendimento),
  );

  const $convenio = computed(() => {
    const agendamento = $agendamento.value;
    const paciente = $paciente.value;

    return (
      agendamento?.convenio?.nome ||
      paciente?.convenios?.[0]?.convenio.nome ||
      'PARTICULAR'
    );
  });

  const $dataNascimento = computed(() => {
    const nasc = $paciente.value?.dataNascimento;

    return (
      nasc && `${DateHelpers.idade(nasc)} (${DateHelpers.formatDate(nasc)})`
    );
  });

  return {
    $agendamento,
    $paciente,
    $faltas,
    $atendimentos,
    $convenio,
    $dataNascimento,
    $ultimoAtendimento,
  };
}
