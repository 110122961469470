import { useState, useValue } from '@/lib/composables';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent, watchRun } from '@/lib/vue';
import { ProntuarioState } from '@/store/modules/prontuario';
import { computed, onUnmounted, Ref } from '@vue/composition-api';

export const ProntuarioAtendimentoTimer = createComponent({
  name: 'ProntuarioAtendimentoTimer',
  setup(props, ctx) {
    const $startAt = useState(s => s.prontuario.atendimento.startAt);

    const $playing = useState(s => s.prontuario.atendimento.playing);

    const $show = useState(s => s.prontuario.atendimento.showTimer);

    const [$now, setNow] = useValue(new Date());

    const [$interval, set$Interval] = useValue<NodeJS.Timeout | null>(null);

    const $label = computed(() => ($show.value ? 'Ocultar' : 'Exibir'));

    const $timer = computed(() => {
      if (!$startAt.value || !$playing.value || $startAt.value > $now.value) {
        return '00:00:00';
      }

      return DateHelpers.dateDiff(
        $startAt.value,
        $now.value,
        'milliseconds',
      )!.toFormat('hh:mm:ss');
    });

    useEvents({
      $playing,
      $interval,
      set$Interval,
      setNow,
    });

    return () => (
      <div class="flex items-center mx-4 mt-2">
        <v-icon>{MyIcons.timer}</v-icon>

        {$show.value && (
          <div class="ml-1 text-title font-normal text-coolGray-700">
            {$timer.value}
          </div>
        )}

        <v-spacer />

        <v-btn
          text
          color={MyTheme.coolGray600}
          ripple={false}
          class="no-focus"
          onClick={ProntuarioState.atendimento.timer.toggle}
        >
          {$label.value}
        </v-btn>
      </div>
    );
  },
});

function useEvents({
  $playing,
  $interval,
  set$Interval,
  setNow,
}: {
  $playing: Ref<boolean>;
  $interval: Ref<NodeJS.Timeout | null>;
  set$Interval: (v: NodeJS.Timeout | null) => void;
  setNow: (v: Date) => void;
}) {
  function startInterval() {
    set$Interval(setInterval(() => setNow(new Date()), 1000));
  }

  function endInterval() {
    if ($interval.value) {
      clearInterval($interval.value);
    }
  }

  watchRun($playing, playing => (playing ? startInterval() : endInterval()));

  onUnmounted(endInterval);
}
