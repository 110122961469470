import { useState } from '@/lib/composables';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { ProntuarioService } from '@/lib/services/profissional/prontuario/prontuarioService/prontuario.service';
import { createComponent } from '@/lib/vue';
import { computed, Ref } from '@vue/composition-api';

export const ProntuarioAtendimentoBtn = createComponent({
  name: 'ProntuarioAtendimentoBtn',
  setup(props, ctx) {
    const { $playing, $label, $icon, $color } = useComputeds();

    const { handleClick } = useEvents($playing);

    return () => (
      <div class="flex">
        <v-btn
          color={$color.value}
          class="mx-4 no-focus flex-grow"
          onClick={handleClick}
        >
          <v-icon left>{$icon.value}</v-icon>

          {$label.value}
        </v-btn>
      </div>
    );
  },
});

function useComputeds() {
  const $playing = useState(s => s.prontuario.atendimento.playing);

  const $label = computed(() =>
    $playing.value ? 'Finalizar atendimento' : 'Iniciar atendimento',
  );

  const $icon = computed(() => ($playing.value ? MyIcons.stop : MyIcons.play));

  const $color = computed(() => ($playing.value ? 'error' : 'primary'));

  return { $playing, $label, $icon, $color };
}

function useEvents($playing: Ref<boolean>) {
  function handleClick() {
    $playing.value
      ? ProntuarioService.atendimento.end()
      : ProntuarioService.atendimento.start();
  }

  return { handleClick };
}
